var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seoJobsOccupationDetail" },
    [
      _c(
        "div",
        { staticClass: "seoJobsOccupationDetail__contentElements" },
        [
          _c("content-element-children", {
            attrs: { children: _vm.seoJobOccupation.content.children },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hideLatestJobs
        ? _c(
            "green-container",
            [
              _c("job-item-list", { attrs: { jobs: _vm.relatedApiJobs } }),
              _vm._v(" "),
              _c("JobButtons", {
                attrs: { context: _vm.context.pageContext },
                on: { listClick: _vm.onListClick },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }