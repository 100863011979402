































import {isEmpty} from '@labor-digital/helferlein';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import JobButtons from '../../../../Component/Job/JobButtons/JobButtons.vue';
import JobItemList from '../../../../Component/Job/JobItemList/JobItemList.vue';
import GreenContainer from '../../../../Component/Layout/GreenContainer/GreenContainer.vue';
import OpenGraphMixin from '../../../../Mixin/OpenGraphMixin';
import SeoJobsMixin from '../../../../Mixin/SeoJobsMixin';
import {JsonLdService} from '../../../../Service/JsonLdService';

export default {
    name: 'SeoJobsOccupationDetail',
    mixins: [SeoJobsMixin, OpenGraphMixin],
    components: {JobButtons, GreenContainer, JobItemList},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        seoJobOccupation(): PlainObject
        {
            return this.context.initialState.response.data;
        },
        hideLatestJobs(): Boolean
        {
            return this.context.data.get('latestJobs') ?? false;
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.seoJobOccupation.description ?? this.seoJobOccupation.headline);
        }
    },
    metaInfo()
    {
        return {
            title: isEmpty(this.seoJobOccupation.metaTitle) ? this.seoJobOccupation.headline : this.seoJobOccupation.metaTitle,
            meta: this.metaTags.concat([
                {
                    'name': 'description',
                    'content': this.ogDescription,
                    'vmid': 't3-description'
                }
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.seoJobOccupation.name)
            ]
        };
    },
    created()
    {
        const occupation = this.seoJobOccupation;
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, occupation.headline);
        if (occupation.teaserImages) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE, occupation.teaserImages);
        }
        if (occupation.useVideoHeader) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_VIDEO_OVERRIDE, occupation.mediaVideo);
            this.context.store.set(AppStoreKeys.PAGE_TEASER_VIDEO_POSTER_OVERRIDE, occupation.mediaVideoPoster);
        }
        this.context.pageContext.pageMeta.setCanonical(occupation.link);
    }
};
